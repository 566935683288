import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Baunebenkosten = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Mit diesen Baunebenkosten musst du rechnen" showCalc={false} />
            <Article>
                <p>
                    Als zukünftige*r Bauherr*in solltest du einen genauen Kostenplan für dein Bauvorhaben erstellen
                    können. Dabei fallen neben den reinen Baukosten auch einige Baunebenkosten an, über die wir dich
                    hier informieren wollen. Mit einem gut überlegten vollständigen Kostenplan steht deiner
                    Baufinanzierung bald nichts mehr im Weg.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Mögliche Baunebenkosten im Überblick</h2>
                <p>
                    Unabhängig von dem Kaufpreis, den Material- und Arbeitskosten eines Bauvorhabens, gibt es wie bei
                    jeder Immobilienfinanzierung noch einige{" "}
                    <Link to="/artikel/hausbau-kosten/" target="_blank" rel="noreferrer noopener">
                        Nebenkosten
                    </Link>{" "}
                    zu beachten. Ein kleiner Überblick:
                </p>
                <ul>
                    <li>die Grunderwerbsteuer</li>
                    <li>Makler*innenprovision</li>
                    <li>Kosten für den Grundbucheintrag</li>
                    <li>Notarkosten</li>
                    <li>Gebühren für Baugenehmigungen</li>
                    <li>Gutachten</li>
                    <li>Versicherungen</li>
                    <li>Anschlusskosten für Wasser, Kanal und Strom</li>
                    <li>Baustelleneinrichtung</li>
                </ul>
                <p>
                    Zusätzlich zum Kaufpreis gibt es wie bei jedem Immobilienkredit noch eine Grunderwerbsteuer, die zu
                    zahlen ist. Eventuell fällt auch eine Makler*innenprovision an und auf die Gebühr für die
                    Grundbucheintragung und die Notarkosten solltest du auch nicht vergessen. Oft müssen für die
                    Erteilung einer Baugenehmigung einige hundert Euro veranschlagt werden.
                </p>
                <p>
                    Je nach Baugrund musst du vielleicht auch ein Baugutachten erstellen lassen, für das du ebenfalls
                    einige hundert bis tausend Euro zahlen musst. All diese Baunebenkosten sollten im Vorhinein
                    eingeplant werden, bevor du dich mit deinem Finanzierungsplan an eine Bank wendest.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Achte auf die Leistungsbeschreibung im Bauvertrag</h2>
                <p>
                    Damit du während der Bauphase nicht von weiteren Baunebenkosten überrascht wirst, sollte die
                    Leistungsbeschreibung des Bauvertrags ganz genau studiert werden. Hier gibt es einige Dinge zu
                    beachten. Es kann zum Beispiel der Fall sein, dass für den geplanten Keller Erdreich ausgehoben und
                    abtransportiert werden muss. Wenn auf der Baustelle nicht genug Platz für die Verteilung der Erde
                    ist, musst du mit Entsorgungskosten rechnen. Außerdem solltest du bereits früh in Erfahrung bringen,
                    ob eine Baustraße für schwere Fahrzeuge errichtet werden muss. Wenn eine solche Schotterstraße erst
                    gemacht werden muss, kannst du mit ca. 150 Euro pro Meter rechnen.
                </p>
                <hr />

                <h2>Was eine Verzögerung der Bauzeit dich kosten könnte</h2>
                <p>
                    Wenn der Hausbau sich verzögert, fallen zusätzliche Kosten bei der Bank an. Auch das können
                    unerwartete Baunebenkosten werden, die dir das Projekt erschweren. Nach etwa drei Monaten verlangt
                    die Bank sogenannte{" "}
                    <Link to="/artikel/bauzinsen/" target="_blank" rel="noreferrer noopener">
                        Bereitstellungszinsen
                    </Link>{" "}
                    für die noch nicht abgerufene Darlehenssumme. Es handelt sich dabei zwar nur um 0,2 - 0,3 Prozent
                    der Darlehenssumme, aber bei Beträgen von 100.000 Euro sind das 200 - 300 Euro zusätzlich im Monat.
                    Nebenbei ist selbstverständlich auch noch deine eigentliche Darlehenssumme weiterhin bei vollem
                    Zinssatz abzubezahlen. Es kann ganz schön schwierig werden, wenn man sich hier verkalkuliert.
                </p>
                <hr />

                <h2>Kalkuliere lieber von Anfang an großzügig!</h2>
                <p>
                    Wenn du keine finanziellen Reserven mehr hast, um deine Baunebenkosten zu begleichen, wird eine
                    Nachfinanzierung notwendig, für die deine Bank höhere Zinsen verlangt. Allerdings kann auch eine zu
                    großzügige Kalkulation dir Nachteile bringen, wenn die Bank eine Vorfälligkeitsentschädigung
                    verlangt. Dagegen kannst du dich aber schützen, wenn du rechtzeitig eine schriftliche Vereinbarung
                    triffst. Du solltest also nicht übertreiben, aber ein Sicherheitspuffer kann niemals schaden. Das
                    Wichtigste ist eine gute und genaue Planung deiner Kosten. Nach der Lektüre dieses Artikels solltest
                    du eine bessere Vorstellung von den Gesamtkosten bekommen haben. Jetzt kannst du dich nach der
                    besten Finanzierung für dein Bauvorhaben umsehen. Probiere unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    aus, um dir einen ersten Überblick zu verschaffen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"baunebenkosten"}></BreadcrumbList>
            <ArticleStructuredData page={"baunebenkosten"} heading={"Mit diesen Baunebenkosten musst du rechnen"} />
        </Layout>
    );
};

export default Baunebenkosten;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.artikel.baunebenkosten"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
